import React from "react"
import { Link } from "gatsby"
import downloadFile from '../img/credit-application.pdf' 
import downloadFile2 from '../img/gh-esg-statement.pdf' 
//import Phone from "../components/phone"

export default function Footer() {
    return (
        <div className="container site-content__contact">
        <div className="wrapper--outer">
          <h2>Get in touch:</h2>
          <p className="email">
            <a href="mailto:info@gypsyhub.com.au">info@gypsyhub.com.au</a>
          </p>
          <p className="email phone">
            {/* <Phone></Phone> */}
          </p>
          <p className="wholesale">
          Wholesale or trade enquiries for our brands <a href="mailto:anthonyp@gypsyhub.com.au">click here</a>
          </p>
          <ul className="site-info__social">
            <li>
              <a href="https://www.linkedin.com/company/gypsy-hub/" target="_blank" rel="noreferrer">
                <span className="screen-reader-text">Linkedin</span>
                <svg className="icon icon__linkedin"><use xlinkHref="#linkedin"></use></svg>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/GypsyHubAustralia/" target="_blank" rel="noreferrer">
                <span className="screen-reader-text">Facebook</span>
                <svg className="icon icon__facebook"><use xlinkHref="#facebook"></use></svg>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/gypsyhub/" target="_blank" rel="noreferrer">
                <span className="screen-reader-text">Instagram</span>
                <svg className="icon icon__instagram"><use xlinkHref="#instagram"></use></svg>
              </a>
            </li>
          </ul>
          <Link to="/terms" className="terms-link">Terms of Service</Link><span className="terms-link"> | </span>
          <a href={downloadFile2} className="terms-link" target="_blank" rel="noreferrer">ESG Statement</a><span className="terms-link"> | </span><a href={downloadFile} className="terms-link" target="_blank" rel="noreferrer">Credit Application Form</a>
            <div id="mc_embed_signup">
            <form action="https://gypsyhub.us18.list-manage.com/subscribe/post?u=96f48b0dfd1a6043590773ac4&amp;id=adcba87257" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
              <div id="mc_embed_signup_scroll">

            	<input type="email" defaultValue="" name="EMAIL" className="email-mailchimp" id="mce-EMAIL" placeholder="Join our mailing list" required />

                <div style={{position: `absolute`, left: `-5000px`}} aria-hidden="true"><input type="text" name="b_96f48b0dfd1a6043590773ac4_adcba87257" tabIndex="-1" defaultValue="" /></div>
                  <input type="submit" value="→" name="subscribe" id="mc-embedded-subscribe" className="button signup-button"  />
              </div>
            </form>
            </div>
        </div>
    </div>
    )
}
